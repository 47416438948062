// Font
$base-font-size: 18px
$base-line-height: 26px
$em-base: $base-font-size // for bourbon px-to-em function.
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif

// Disable bourbon prefixing, handled by autoprefixer.
$prefix-for-webkit: false
$prefix-for-mozilla: false
$prefix-for-microsoft: false
$prefix-for-opera: false
$prefix-for-spec: false
