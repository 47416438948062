// Custom colors
$primary: #0af
$black: #333
$background: #fff

// Blueprint colors
$font-color: $black
$header-color: $font-color - #111

$link-color: $primary
$link-hover-color: $black
$link-focus-color: $link-hover-color
$link-active-color: $link-color
$link-visited-color: $link-color
