// Susy Configuration
$susy: (
  columns: 12,
  gutters: (30/67),
  global-box-sizing: border-box
);

$desktop_layout: (
  columns: 12,
  gutters: (30/67)
);

// Breakpoints reference.
$breakpoint-iphone-portrait: 320px;
$breakpoint-iphone-landscape: 480px;
$breakpoint-ipad-portrait: 768px;
$breakpoint-ipad-landscape: 1024px;


// Define breakpoints.
// @see src/vendors/sass/stimul-breakpoint/_stimul-breakpoint.sass
$breakpoints: (
  "mobile": $breakpoint-iphone-portrait,
  "tablet": $breakpoint-ipad-portrait,
  "desktop": 1280px
);

// We can have different margins by breakpoint.
$breakpoints_margin: (
  "mobile": 22px,
  //"tablet": 15px,
  //"desktop": 30px
);

@mixin desktop($layout: $desktop_layout) {
  @include grid-media(">=desktop", $desktop_layout) {
    @content;
  }
}

@mixin no-desktop($layout: $susy) {
  @include grid-media("<desktop", $layout) {
    @content;
  }
}

@mixin tablet($layout: $susy) {
  @include grid-media(">=tablet", $layout) {
    @content;
  }
}

@mixin tablet-only($layout: $susy) {
  @include grid-media(">=tablet <desktop", $layout) {
    @content;
  }
}

@mixin mobile-only($layout: $susy) {
  @include grid-media("<tablet", $layout) {
    @content;
  }
}
