@import "utils";

$breakpoints: (
  "mobile": 320px,
  "tablet": 740px,
  "desktop": 960px
) !default;

$breakpoints_margin: (
  "mobile": 20px,
) !default;

//https://github.com/ericam/susy/blob/master/sass/susy/language/susy/_breakpoint-plugin.scss
@mixin grid-media($query, $layout: false) {
  $query_list: _ss-str-explode($query, ' ');
  @if (length($query_list) == 1) {
    @include media($query) {
      @if $layout {
        @include with-layout($layout) {
          @content;
        }
      } @else {
        @content;
      }
    }
  }
  @elseif (length($query_list) == 2) {
    $min: nth($query_list, 1);
    $max: nth($query_list, 2);
    @include media($min, $max) {
      @if $layout {
        @include with-layout($layout) {
          @content;
        }
      } @else {
        @content;
      }
    }
  }
}

@mixin grid-media-container($max: false) {
  $last_name: false;
  $last_margin: 0;
  @each $name, $break in $breakpoints {
    @if (map-has-key($breakpoints_margin, $name)) {
      $margin: map-get($breakpoints_margin, $name);
      @if $last_name == false {
        // Special case for first
        @include pie-clearfix();
        max-width: 100%;
        margin: 0 $margin;
      }
      @else {
        @include media(">=" + $name) {
          margin: 0 $margin;
        }
      }

      $last_margin: $margin;
    }
    $last_name: $name;
  }

  // Set the max width
  @if $last_name != false {
    $break: map-get($breakpoints, $last_name);


    @if $max == false {
      //$max: $break - $last_margin * 2;
      $max: $break - 2 * $last_margin;
    }

    $break: $max + 2 * $last_margin;

    @include media('>=' + $break) {
      max-width: $max;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
