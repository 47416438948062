.site-footer
  margin-top: 80px
  margin-bottom: 20px
  padding: 60px 0
  background-color: #3c3c3b
  color: #fff

  &__address, &__horaires
    +span(3)

  &__newsletter
    +span(3)
    +last

  &__address, &__horaires
    ul
      margin: 0
      padding: 0

      li
        +no-bullets
        +font-smoothing

        text-align: left
        font-size: 15px
        line-height: 1.5em

        a
          color: #fff
          text-decoration: underline


  &__newsletter
    text-align: left

    h2
      +font-smoothing
      margin: 0 0 10px 0
      color: #fff
      font-size: 16px
      font-weight: normal
      text-transform: uppercase

    .email
      width: 100%
      margin-bottom: 15px
      padding: 12px 17px
      border: 0
      font-size: 15px
      color: #3b3b3c

    .button
      padding: 10px 15px
      border: 0
      background-color: #bbb
      font-size: 13px
      text-transform: uppercase
      color: #3b3b3c
