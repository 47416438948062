html
  font-family: $base-font-family
  font-size: 100% * ($base-font-size / 16px)
  line-height: $base-line-height
  text-align: center
  font-weight: 300

// Set default heading color.
h1, h2, h3, h4, h5, h6
  color: $header-color

// Set default links color.
a
  +link-colors($header-color, $header-color, $header-color, $header-color, $header-color)
  text-decoration: none

// Remove default paragraph margin top.
p
  margin-top: 0

// utils
.hidden
  position: absolute !important
  clip: rect(1px 1px 1px 1px) /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px)
  padding: 0 !important
  border: 0 !important
  height: 1px !important
  width: 1px !important
  overflow: hidden
