.site-content
  &__main

    h2
      margin: 30px 0 20px 0
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif
      font-size: 21px
      font-weight: normal
      text-transform: uppercase

      &:first-child
        margin-top: 0

    p
      font-size: 17px
