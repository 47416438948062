.open-hours
  +reset-box-model
  margin-bottom: 22px

  &__item
    +reset-box-model
    list-style: none
    display: block
    margin: 0 12px

  &__day
    +font-smoothing
    display: inline-block

  &__hours
    +last
    display: inline-block
