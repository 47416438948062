.bx-wrapper
  position: relative
  margin: 0
  padding: 0
  *zoom: 1

  img
    max-width: 100%
    display: block

.bx-viewport
  /*fix other elements on the page moving (on Chrome)*/
  transform: translateZ(0)

.bxslider
  +reset-box-model
  +no-bullets
